import { Image } from 'antd'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import React from 'react'
import img from "assets/images/lil.jpg"
import { Helmet } from 'react-helmet-async'

export default function Williams() {
    return (
        <>
        <Helmet prioritizeSeoTags>
            <title>Lordwilliams EA Greatness</title>
            <link rel="notImportant" href="https://www.quickdirect.com.ng" />
            <meta name="whatever" value="notImportant" />
            <link rel="canonical" href="https://www.quickdirect.com.ng" />
            <meta property="og:title" content="Lordwilliams EA Greatness" />
            <meta property="og:url" content="https://www.quickdirect.com.ng" />
            <meta property="og:image" content="https://www.quickdirect.com.ng/static/media/lil.0c530d9b0bd6fafee888.jpg" />
            <meta property="og:type" content="website" />
            <meta name="description" property="og:description" content="Lordwilliams is the visionary CEO of Quick Direct, a fintech company transforming the digital payment landscape. With a passion for innovation and a commitment to excellence, he has spearheaded Quick Direct's rapid growth and expansion" />
            <meta name="keywords" property="og:keywords" content="Quick Direct, Lordwilliams, CEO of Quick Direct, empowering individuals and institutions to achieve their financial goals,revolutionize the financial industry, harnessing the power of innovation, expertise, and technology" />

        </Helmet>
            <Header />
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 w-11/12 lg:w-10/12 mx-auto my-20'>
                <div className='order-2 lg:order-1'>
                    <h1 className='font-bold text-4xl lg:text-5xl'>Lordwilliams EA Greatness</h1>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Early Life and Education</h2>

                    <div>Lordwilliams EA Greatness was born to Ijeoma Mercy and Aregbeshola in Ondo State, Nigeria. From a young age, Greatness exhibited a keen interest in technology and business, which would later define his career path. His early education began at Yetba Primary School, where his enthusiasm for learning was evident. He continued his education at Sulibright College, excelling in subjects that required analytical thinking and creativity. Despite not pursuing further formal education, Greatness's self-motivation and determination to succeed laid a strong foundation for his future endeavors.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Career Beginnings</h2>

                    <div>Greatness's professional journey began in the field of graphic design. His exceptional artistic skills and ability to understand the technical aspects of design allowed him to create visually compelling and innovative graphics. This early experience not only honed his creative abilities but also instilled in him a deep understanding of the importance of technology in the modern world.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Founding Subhub-Tech</h2>

                    <div>In a significant leap forward, Lordwilliams EA Greatness founded Subhub-Tech, a technology company aimed at driving innovation and providing cutting-edge tech solutions. As the founder and CEO, he led the company to remarkable success, introducing groundbreaking products and services that set new industry standards. Under his visionary leadership, Subhub-Tech grew rapidly, earning a reputation for excellence and innovation.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Transition to Finance</h2>

                    <div>Following his achievements in the tech industry, Greatness transitioned to the financial sector. He took on the role of head trader at FCMB Bank, where he applied his analytical prowess and strategic mindset to navigate the complexities of financial markets. His tenure at FCMB was marked by significant successes, demonstrating his ability to thrive in high-stakes environments.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Fund Management at IGT Tech</h2>

                    <div>Continuing his journey in finance, Greatness served as an ex-fund manager at IGT Tech. In this role, he managed substantial investment portfolios, utilizing his deep understanding of market dynamics and risk management. His strategic decisions and innovative approach to fund management contributed to the firm’s growth and cemented his reputation as a financial expert.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Founding Quick Direct</h2>

                    <div>Currently, Lordwilliams EA Greatness is the founder and CEO of Quick Direct, a multi-sector business company that operates across various domains including real estate, agriculture, forex and crypto trading, flight and hotel booking, and telecom services. Quick Direct is committed to providing comprehensive financial services and investment opportunities, aiming to revolutionize the financial landscape in Nigeria by offering secure, diverse investment options.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Quick Direct Overview</h2>

                    <div>Quick Direct’s operations span multiple sectors, reflecting Greatness's vision of creating a robust and diversified business model. The company’s activities include:</div>

                    <p>- *Real Estate:* Focusing on buying and selling properties, offering short-term rentals, and providing leasing services.</p>
                    <p>- *Agriculture:* Engaging in the buying and selling of farm produce, aiming to boost local agriculture and ensure food security.</p>
                    <p>- *Crypto and Forex Trading:* Providing a platform for seamless cryptocurrency transactions and forex trading, simplifying financial processes for users.</p>
                    <p>- *Telecom Services:* Offering various communication solutions tailored to meet the needs of modern businesses.</p>
                    <p>- *Flight and Hotel Booking:* Facilitating travel arrangements with comprehensive booking services for flights and hotels.</p>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Commitment to Financial Freedom</h2>

                    <div>The establishment of Quick Direct was driven by Greatness’s desire to address the pervasive issue of financial instability caused by fraudulent investments. The company’s mission is to educate, provide, and reshape investment practices, ensuring that investors have reliable and secure options to grow their wealth.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Our Uniqueness</h2>

                    <div>Quick Direct stands out in the industry due to its commitment to innovation, excellence, and customer satisfaction. By integrating diverse business models and leveraging cutting-edge technology, Quick Direct offers unparalleled services that cater to a wide range of financial needs.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Our Difference</h2>

                    <div>What sets Quick Direct apart is its holistic approach to financial services. Unlike traditional firms, Quick Direct combines multiple sectors under one umbrella, providing clients with comprehensive solutions that are both innovative and reliable.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Our Trustworthiness</h2>

                    <div>At Quick Direct, trust is paramount. The company prioritizes transparency and integrity in all its operations, ensuring that clients have complete confidence in their investment decisions.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Our Commitment</h2>

                    <div>Quick Direct is dedicated to the continuous improvement of its services. The company invests heavily in research and development to stay ahead of industry trends and deliver cutting-edge solutions to its clients.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Our Future Goals</h2>

                    <div>Looking ahead, Lordwilliams EA Greatness and Quick Direct are committed to expanding their impact. The company aims to introduce more innovative financial products and services, foster financial literacy, and continue empowering individuals and businesses to achieve financial freedom.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Achievement</h2>

                    <div>Quick Direct, under the visionary leadership of Lordwilliams EA Greatness, has become a beacon of trust and innovation in Nigeria’s financial sector. The company’s dedication to excellence and its innovative approach continue to set it apart as a leader in the industry.</div>

                    <h2 className="font-bold text-2xl mt-6 mb-3">Conclusion</h2>

                    <div>Lordwilliams EA Greatness’s journey from a graphic designer to the founder and CEO of a multi-sector business company is a testament to his resilience, innovation, and leadership. His contributions to technology and finance are significant, and his work with Quick Direct continues to shape the future of investment in Nigeria. He currently resides in Lagos State, Nigeria, where he manages Quick Direct.</div>
                </div>
                <div className='order-1 lg:order-2'>
                    <div className=' sticky top-0 right-0'>
                        <Image src={img} className='' alt="Lordwilliams EA Greatness" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
