import { MenuItem } from '@mui/material'
import { dispatchUser } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import UserLayout from 'components/user/UserLayout'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, siteName, SuccessAlert } from 'components/utils/functions'
import Selectinput from 'components/utils/Selectinput'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function UpgradeAccount() {
    const [forms, setForms] = React.useState({
        tag: '',
        number: '',
    })
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch()
    
    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    async function HandleSubmission(e) {
        e.preventDefault()
        if(!forms.number) return ErrorAlert('Kindly provide your ID Number')
        setLoading(true)
        try {
            const formData = {
                tag: 'bvn',
                number: forms.number
            }
            const response = await Authposturl(Apis.wireless.create_virtual_account, formData)
            if(response.status !== 200) return ErrorAlert(`${response.msg}`)
                SuccessAlert(response.msg)
            dispatch(dispatchUser(response.user))
            navigate('/dashboard')
        } catch (error) {
            ErrorAlert(error.message)
        }finally {
            setLoading(false)
        }
    }
    return (
        <UserLayout title={'Upgrade Account'} upgrade={false}>
            <div className="bg-white p-5 w-11/12 mx-auto mt-5">
            <form onSubmit={HandleSubmission}>
                <div className="mb-3 pb-3 border-b">To comply with CBN Regulations, {siteName} require you validate your Vaid ID number (i.e BVN)                </div>
                <div className="text-2xl font-bold mb-5 mt-10">Bank Verification Number (BVN)</div>
                <div className="mb-4">
                    <div className="">Identification Number</div>
                    <Forminput label={'ID Number'} onChange={handleForms} value={forms.number} formName={'number'} />
                </div>
                <div className="text-sm text-gray-600">Kindly note that, you are liable to the informations provided, and as such your account could be suspended upon wrong details, you can review all informations before submitting.</div>
                <div className="grid grid-cols-1 md:grid-cols-2 mt-5">
                    <div></div>
                    <Formbutton title="Submit" loading={loading} />
                </div>
            </form>
            </div>
        </UserLayout>
    )
}
