import Williams from "pages/general/Lordwilliams"
import AllFeeds from "./pages/admin/AFeeds/AllFeeds"
import CreateFeeds from "./pages/admin/AFeeds/CreateFeeds"
import EditFeed from "./pages/admin/AFeeds/EditFeed"
import AHome from "./pages/admin/AHome"
import AProfile from "./pages/admin/AProfile"
import AirtimeCashbacks from "./pages/admin/AirtimeCashbacks/AirtimeCashbacks"
import AllUsers from "./pages/admin/AllUsers/AllUsers"
import ACategory from "./pages/admin/Categories/ACategory"
import AllChats from "./pages/admin/Chats/AllChats"
import ChatSupportAdmin from "./pages/admin/Chats/ChatSupportAdmin"
import Managers from "./pages/admin/InvestmentManager/Managers"
import AllPManageKycUploadsickups from "./pages/admin/ManageKyc/ManageKycUploads"
import AllServices from "./pages/admin/ManageServices/AllServices"
import NewService from "./pages/admin/ManageServices/NewService"
import SetupMargin from "./pages/admin/ManageServices/SetupMargin"
import AShortlets from "./pages/admin/ManageShortlets/AShortlets"
import EditShortlet from "./pages/admin/ManageShortlets/EditShortlet"
import NewShortlet from "./pages/admin/ManageShortlets/NewShortlet"
import SendNotification from "./pages/admin/NotificationPanel/SendNotification"
import ManagePartnersLogo from "./pages/admin/PartnersLogo/ManagePartnersLogo"
import AllPickups from "./pages/admin/Pickups/AllPickups"
import APlans from "./pages/admin/Plans/APlans"
import CreatePlan from "./pages/admin/Plans/CreatePlan"
import EditPlan from "./pages/admin/Plans/EditPlan"
import AllServiceTransactions from "./pages/admin/ServiceTransactions/AllServiceTransactions"
import AllWithdrawOrders from "./pages/admin/WithdrawOrder/AllWithdrawOrders"
import ForgotPassword from "./pages/forms/ForgotPassword"
import Login from "./pages/forms/Login"
import RegisterPage from "./pages/forms/Register"
import Welcome from "./pages/forms/Welcome"
import Aboutus from "./pages/general/Aboutus"
import Faqs from "./pages/general/Faqs"
import Home from "./pages/general/Home"
import HomeShortletPage from "./pages/general/HomeShortlets/HomeShortletPage"
import HomeShortlets from "./pages/general/HomeShortlets/HomeShortlets"
import Partnership from "./pages/general/Partnership"
import PartnerHome from "./pages/general/PartnershipPortal/PartnerHome"
import PrivacyPolicy from "./pages/general/PrivacyPolicy"
import Terms from "./pages/general/Terms"
import ManageAsset from "./pages/admin/CoinAsset/ManageAsset"
import AssignUsers from "./pages/admin/AssignAdmins/AssignUsers"
import ManageDepartment from "./pages/admin/Department/ManageDepartment"
import PortalHome from "./pages/superadmin/PortalHome"
import SProfile from "./pages/superadmin/SProfile"
import ManageShareAsset from "./pages/admin/Shares/ShareAsset/ManageShareAsset"
import SharesRequests from "./pages/admin/Shares/ShareRequests/SharesRequests"
import ManageShareclass from "./pages/admin/Shares/Shareclass/ManageShareclass"
import ManageShareholders from "./pages/admin/Shares/Shareholders/ManageShareholders"
import Dashboard from "./pages/user/Dashboard"
import FeedPage from "./pages/user/FeedPage"
import Feeds from "./pages/user/Feeds"
import FundAccount from "./pages/user/FundAccount"
import Invests from "./pages/user/Investments/Invests"
import Istock from "./pages/user/Investments/Istock/Istock"
import ManageIstockHistory from "./pages/user/Investments/Istock/ManageIstockHistory"
import TradeIstock from "./pages/user/Investments/Istock/TradeIstock"
import ManageMidasHistory from "./pages/user/Investments/Midas/ManageMidasHistory"
import Midas from "./pages/user/Investments/Midas/Midas"
import TradeMidas from "./pages/user/Investments/Midas/TradeMidas"
import ManageprimeHistory from "./pages/user/Investments/Prime/ManageprimeHistory"
import Prime from "./pages/user/Investments/Prime/Prime"
import TradePrime from "./pages/user/Investments/Prime/TradePrime"
import VerifyPlanPay from "./pages/user/Investments/VerifyPlanPay"
import UploadKyc from "./pages/user/KycStages/UploadKyc"
import Notifications from "./pages/user/Notifications"
import Profile from "./pages/user/Profile"
import A2C from "./pages/user/Services/A2C"
import Airtime from "./pages/user/Services/Airtime"
import Cable from "./pages/user/Services/Cable"
import Data from "./pages/user/Services/Data"
import Electric from "./pages/user/Services/Electric"
import ExamPin from "./pages/user/Services/ExamPin"
import STransactionPage from "./pages/user/Services/STransactionPage"
import ServiceTransactions from "./pages/user/Services/ServiceTransactions"
import VerifyServicePay from "./pages/user/Services/VerifyServicePay"
import RequestPickup from "./pages/user/Shortlets/RequestPickup"
import Shortlets from "./pages/user/Shortlets/Shortlets"
import SingleShort from "./pages/user/Shortlets/SingleShort"
import SupportPage from "./pages/user/Support/SupportPage"
import Transactions from "./pages/user/Transactions"
import UserShare from "./pages/user/UserShares/UserShare"
import VerifyFund from "./pages/user/VerifyFund"
import MyPickupPage from "./pages/user/pages/MyPickupPage"
import MyPickups from "./pages/user/pages/MyPickups"
import Pages from "./pages/user/pages/Pages"
import Notfound from "pages/general/Notfound"
import ManageServiceApi from "pages/admin/ServiceApiTrack/ManageServiceApi"
import VerifyPartnership from "pages/general/VerifyPartnership"
import NewsfeedsPage from "pages/general/NewsfeedsPage"
import HomeNews from "pages/general/HomeNews"
import AllAccountPlans from "pages/admin/AccountPlans/AllAccountPlans"
import CashbackHistory from "pages/user/CashbackHistory"
import ManageCashbackWithdraws from "pages/admin/CashbackHistories/ManageCashbackWithdraws"
import Biznet from "pages/user/BizNet/Biznet"
import BizTransactions from "pages/user/BizNet/BizTransactions"
import Referrals from "pages/user/Referrals"
import SendMoney from "pages/user/SendMoney"
import RedeemReward from "pages/user/RedeemReward"
import AllWithdrawRequests from "pages/admin/WithdrawRequests/AllWithdrawRequests"
import WithdrawalHistory from "pages/admin/Histories/WithdrawalHistory"
import DepositHistory from "pages/admin/Histories/DepositHistory"
import DataRewards from "pages/admin/DataRewards/DataRewards"
import UpgradeAccount from "pages/user/UpgradeAccount"
import AllBeneficiaries from "pages/user/AllBeneficiaries"
import AllVirtualAccounts from "pages/admin/AllVirtualAccounts"
import VcTransactionHistory from "pages/admin/VcTransactionHistory"
import ManageNcwalletServices from "pages/admin/Ncwallet/ManageNcwalletServices"
import NewNcwalletService from "pages/admin/Ncwallet/NewNcwalletService"
import EditNcwalletServices from "pages/admin/Ncwallet/EditNcwalletServices"
import WPsettings from "pages/admin/WPsettings"


export const AdminPages = [
    {path: "", component: AHome},
    {path: "profile", component: AProfile},
    {path: "investment_managers", component: Managers},
    {path: "transactions/services", component: AllServiceTransactions},
    {path: "transactions/cashbacks", component: ManageCashbackWithdraws},
    {path: "feeds", component: AllFeeds},
    {path: "feeds/new", component: CreateFeeds},
    {path: "feeds/edit/:slug/:id", component: EditFeed},
    {path: "plans", component: APlans},
    {path: "plans/new", component: CreatePlan},
    {path: "plans/edit/:slug/:id", component: EditPlan},
    {path: "all-users", component: AllUsers},
    {path: "chats", component: AllChats},
    {path: "withdrawal-orders", component: AllWithdrawOrders},
    {path: "withdrawal-requests", component: AllWithdrawRequests},
    {path: "send-notification", component: SendNotification},
    {path: "manage-kyc", component: AllPManageKycUploadsickups},
    {path: "categories", component: ACategory},
    {path: "shortlets", component: AShortlets},
    {path: "shortlets/new", component: NewShortlet},
    {path: "shortlets/edit/:id", component: EditShortlet},
    {path: "pickups", component: AllPickups},
    {path: "services", component: AllServices},
    {path: "services/new", component: NewService},
    {path: "services/:tag", component: SetupMargin},
    {path: "chat-support", component: ChatSupportAdmin},
    {path: "partners-logo", component: ManagePartnersLogo},
    {path: "airtime-cashbacks", component: AirtimeCashbacks},
    {path: 'assign-users', component: AssignUsers},
    {path: 'asset', component: ManageAsset},
    {path: 'share/asset', component: ManageShareAsset},
    {path: 'share/class', component: ManageShareclass},
    {path: 'share/holders', component: ManageShareholders},
    {path: 'share/requests', component: SharesRequests},
    {path: 'manage-departments', component: ManageDepartment},
    {path: 'api-wallet-history', component: ManageServiceApi},
    {path: 'account-plans', component: AllAccountPlans},
    {path: 'all-withdrawals', component: WithdrawalHistory},
    {path: 'all-deposits', component: DepositHistory},
    {path: 'data-rewards', component: DataRewards},
    {path: 'virtual_accounts', component: AllVirtualAccounts},
    {path: 'virtual_account/:id', component: VcTransactionHistory},
    {path: 'ncwallet-services', component: ManageNcwalletServices},
    {path: 'ncwallet-services/new', component: NewNcwalletService},
    {path: 'ncwallet-services/:id/edit', component: EditNcwalletServices},
    {path: 'wp-settings', component: WPsettings},
]

export const SuperAdminPages = [
    {path: '', component: PortalHome},
    {path: 'profile', component: SProfile},
    {path: 'assign-users', component: AssignUsers},
    {path: 'asset', component: ManageAsset},
    {path: 'share/asset', component: ManageShareAsset},
    {path: 'share/class', component: ManageShareclass},
    {path: 'share/holders', component: ManageShareholders},
    {path: 'share/requests', component: SharesRequests},
    {path: 'manage-departments', component: ManageDepartment},
]

export const UserPages = [
    {path: "/dashboard", component: Dashboard},
    {path: "/dashboard/shortlets", component: Shortlets},
    {path: "/dashboard/shortlets/:slug/:id", component: SingleShort},
    {path: "/dashboard/shortlets/:id/pickup", component: RequestPickup},
    {path: "/my-shares", component: UserShare},
    {path: "/support", component: SupportPage},
    {path: "/profile", component: Profile},
    {path: "/notifications", component: Notifications},
    {path: "/transactions", component: Transactions},
    {path: "/service_transactions", component: ServiceTransactions},
    {path: "/service_transactions/:id", component: STransactionPage},
    {path: "/investment", component: Invests},
    {path: "/investment/:id/istock", component: Istock},
    {path: "/investment/:id/istock/manage", component: ManageIstockHistory},
    {path: "/investment/:id/istock/trade", component: TradeIstock},
    {path: "/investment/:id/prime", component: Prime},
    {path: "/investment/:id/prime/manage", component: ManageprimeHistory},
    {path: "/investment/:id/prime/trade", component: TradePrime},
    {path: "/investment/:id/midas", component: Midas},
    {path: "/investment/:id/midas/manage", component: ManageMidasHistory},
    {path: "/investment/:id/midas/trade", component: TradeMidas},
    {path: "/fund-account", component: FundAccount},
    {path: "/fund-account/verify", component: VerifyFund},
    {path: "/kyc", component: UploadKyc},
    {path: "/airtime", component: Airtime},
    {path: "/data", component: Data},
    {path: "/electricity", component: Electric},
    {path: "/cable", component: Cable},
    {path: "/airtime_to_cash", component: A2C},
    {path: "/exam_pin", component: ExamPin},
    {path: "/verify-pay/:id/:service", component: VerifyServicePay},
    {path: "/very-investment-purchase/:invoiceid/:walletid/:wallet", component: VerifyPlanPay},
    {path: "/pages", component: Pages},
    {path: "/pages/orders", component: MyPickups},
    {path: "/pages/orders/:id", component: MyPickupPage},
    {path: "/feeds", component: Feeds},
    {path: "/feeds/:slug/:id", component: FeedPage},
    {path: "/cashbacks", component: CashbackHistory},
    {path: "/biznet", component: Biznet},
    {path: "/biznet/transactions", component: BizTransactions},
    {path: "/referrals", component: Referrals},
    {path: "/send-money", component: SendMoney},
    {path: "/redeem-reward", component: RedeemReward},
    {path: "/upgrade", component: UpgradeAccount},
    {path: "/all-beneficiaries", component: AllBeneficiaries},
]

export const PartnersPages = [
    {path: "/eu/partner", component: PartnerHome}
]

export const GeneralPages = [
    {path: "/", component: Home},
    {path: "/lordwilliams", component: Williams},
    {path: "/about-us", component: Aboutus},
    {path: "/privacy-policy", component: PrivacyPolicy},
    {path: "/faqs", component: Faqs},
    {path: "/newsfeeds", component: HomeNews},
    {path: "/terms-and-conditions", component: Terms},
    {path: "/partnership", component: Partnership},
    {path: "/verify-partnership", component: VerifyPartnership},
    {path: "/shortlets", component: HomeShortlets},
    {path: "/shortlets/:slug/:id", component: HomeShortletPage},
    {path: "/newsfeed/:slug/:id", component: NewsfeedsPage},
    {path: "/*", component: Notfound},
]

export const FormPages = [
    {path: "/login", component: Login},
    {path: "/forgot-password", component: ForgotPassword},
    {path: "/signup", component: RegisterPage},
    {path: "/welcome", component: Welcome},
]